<template>
  <div class="aboutus-page">
    <div class="aboutus-title">关于我们</div>
    <div class="aboutus-content" v-html="content"></div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { getconfig } from "@/api/api.js";

const content = ref("<span>加载中...</span>");
function getAboutFun() {
  getconfig("about").then((res) => {

    content.value = res.data;
  });
}
getAboutFun();
</script>


<style lang="scss" scope>
.aboutus-page {
  text-align: start;
  width: 1206px;
  height: 689px;
  background: #32384b;
  padding: 0 73px;
  color: #bfcef8;
  .aboutus-title {
    font-size: 21px;
    padding: 32px 0;
    border-bottom: 1px solid #4b546b;
  }
  .aboutus-content {
    margin-top: 20px;
    height: 500px;
    overflow: auto;
  }
}
</style>